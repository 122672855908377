import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import newsArticle from '../../utils/news.json'
import NewsCardItem from './NewsCardItem'
import Footer from '../Footer'

function News({match}) {
  const [news, setNews] = useState({})

  const fetchPost = useCallback(id => {
    const news = newsArticle.records.filter(item => item.id === parseInt(id))[0];
    console.log(news)
    setNews({ ...news })
  }, [])

  useEffect(() => {
    fetchPost(match.params.newsId)
    // console.log(match.params)
    // console.log(match.params.newsId);
  }, [fetchPost, match.params.newsId])


  return (
    <div>
      {news.text === undefined ? (
        <h1>Loading....</h1>
      ) : (
        <NewsCardItem
          id={news.id}
          icon={news.icon}
          img={news.img}
          text={news.text}
          label={news.label}
          path={news.path}
          date={news.date}
          description={news.description}
        />
      )}

      <Footer />
    </div>
  )
}

export default News
