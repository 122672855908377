import React from 'react';
import '../style.css'
import './Cards.css';

import CardItem from './CardItem';
import CardItemURLpath from './CardItemURLpath'
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import newjson from '../../utils/news.json'

function Cards() {
  // const freshProds = [
  //   {
  //     src: 'images/news/nasa.png',
  //     text: 'Prof. Xiaojiang Li with collaborators from UNC, PNNL, ASU been awarded $1.5 million grant from NASA',
  //     label: 'Grant',
  //     path: '',
  //     date: '10-05-2023'
  //   },
  //   {
  //     src: 'images/news/8-1-2023-nsf-hegs.png',
  //     text: 'Prof. Xiaojiang Li has been awarded NSF grant as sole principle investigator',
  //     label: 'Grant',
  //     path: 'https://www.nsf.gov/awardsearch/showAward?AWD_ID=2314709&HistoricalAwards=false',
  //     date: '08-09-2023'
  //   },
  //   {
  //     src: 'images/news/7-16-2023-urbanclimate-publication.jpeg',
  //     text: 'Prof. Xiaojiang Li has a new paper published on journal of Urban Climate',
  //     label: 'Publication',
  //     path: 'https://www.sciencedirect.com/science/article/abs/pii/S2212095523002092',
  //     date: '07-16-2023'
  //   },
  //   {
  //     src: 'images/news/06-07-2023-ucgis.png',
  //     text: 'Prof. Xiaojiang Li presents at UCGIS sympothum of "Pedestrian level heat exposure modeling and mapping"',
  //     label: 'Presentation',
  //     path: 'https://www.ucgis.org/symposium-2023',
  //     date: '06-07-2023'
  //   },
  //   {
  //     src: 'images/news/05-14-2023-ICB.png',
  //     text: 'Prof. Xiaojiang Li presents at 23rd International Congress of Biometeorology at Arizona State University',
  //     label: 'Presentation',
  //     path: 'https://biomet23.asu.edu/',
  //     date: '05-14-2023'
  //   },
  //   {
  //     src: 'images/news/03-24-2-23-AAG-denver.png',
  //     text: 'Prof. Xiaojiang Li presents at AAG annual conference at Denver',
  //     label: 'Presentation',
  //     path: 'https://www.aag.org/events/aag2023/',
  //     date: '03-24-2023',
  //   },
  //   {
  //     src: 'images/news/02-27-2023-DigitalTwin-asu.png',
  //     text: 'Prof. Xiaojiang Li was invited to present at Workshop of digital twins in Geospatial Research at Arizona State University',
  //     label: 'Presentation',
  //     path: 'https://sgsup.asu.edu/node/9358',
  //     date: '02-27-2023'
  //   },
  //   {
  //     src: 'images/news/02-02-2023-jhu.png',
  //     text: 'Prof. Xiaojiang Li presents at Johns Hopkins Bromery Lectures of "Geospatial Data Science for Sustainability"',
  //     label: 'Presentation',
  //     path: 'https://eps.jhu.edu/events/bromery-lectures/',
  //     date: '02-02-2023'
  //   },
  //   {
  //     src: 'images/news/8-3-2022-harvard.png',
  //     text: 'Prof. Xiaojiang Li presents at Harvard University of "Mapping urban heat exposure in US cities',
  //     label: 'Presentation',
  //     path: 'https://gis.harvard.edu/event/mapping-urban-outdoor-heat-exposure-using-us-cities',
  //     date: '08-02-2022'
  //   },

  //   {
  //     src: 'images/news/8-2-2022-mit.png',
  //     text: 'Prof. Xiaojiang Li made a presentation of "Urban Microclimate Modeling" at Senseable City Lab, MIT',
  //     label: 'Presentation',
  //     path: '',
  //     date: '08-01-2022'
  //   },
  //   {
  //     src: 'images/news/6-2-2022-cornell.png',
  //     text: 'Prof. Xiaojiang Li made a guest lecture of "Urban Analytics for Urban Environmental Sustainability" at at Cornell University',
  //     label: 'Presentation',
  //     path: '',
  //     date: '06-01-2022'
  //   },
  //   {
  //     src: 'images/news/5-2-2022-penn.png',
  //     text: 'Prof. Xiaojiang Li was invited to give a lecture of "Urban Analytics for Urban Environmental Sustainability" at University of Pennsylvania',
  //     label: 'Presentation',
  //     path: '',
  //     date: '05-01-2022'
  //   },

  //   {
  //     src: 'images/news/risingstar.png',
  //     text: 'Prof. Xiaojiang Li has been selected as 50 Rising stars in Geospatial World',
  //     label: 'Award',
  //     path: 'https://www.geospatialworld.net/rising-stars/2021/index.html'
  //   }
  // ]

  // const [visible, setVisible] = useState(6);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 12; // Number of items to display per page
  const numRows = 3;
  const itemsPerRow = itemsPerPage / numRows;

  const [jsonArray, setJsonArray] = useState([]);

  // Function to handle page change
  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * itemsPerPage
    setCurrentPage(offset);
  };

  useEffect(() => {
    const jsonArray = Object.values(newjson.records);
    // Set the length of the JSON object
    setJsonArray(jsonArray)
  }, []);


  // Calculate the start and end index of items for the current page
  // const endIndex = currentPage + itemsPerPage;
  // console.log(currentPage);

  return (
    <>
      <div className='cards'>
        <h1>News!</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            {Array.from(Array(numRows), (e, i) => {
              return (
                <ul className='cards__items'>
                  {
                    jsonArray.slice(currentPage + i * itemsPerRow, currentPage + (i + 1) * itemsPerRow).map(item => (
                      item.path === "none" ? 
                      <CardItemURLpath
                        id={item.id}
                        icon={item.icon}
                        text={item.text}
                        label={item.label}
                        path={item.path}
                        date={item.date}
                      />
                      :
                      <CardItem
                        id={item.id}
                        icon={item.icon}
                        text={item.text}
                        label={item.label}
                        path={item.path}
                        date={item.date}
                      />
                    )
                  )}
                </ul>
              )
            })}

            
            {/* {Array.from(Array(numRows), (e, i) => {
              return (
                <ul className='cards__items'>
                  {freshProds.slice(currentPage + i * itemsPerRow, currentPage + (i + 1) * itemsPerRow).map((freshprod) => (
                    <CardItem
                      src={freshprod.src}
                      text={freshprod.text}
                      label={freshprod.label}
                      path={freshprod.path}
                      date={freshprod.date}
                    />
                  ))}
                </ul>
              )
            })} */}


          </div>
        </div>

        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          // pageCount={Math.ceil(freshProds.length / itemsPerPage)}
          pageCount={Math.ceil(jsonArray.length / itemsPerPage)}
          onPageChange={handlePageChange}
          containerClassName="paginationBttns"
          activeClassName="paginationActive"
        />

      </div>
    </>
  );
}

export default Cards;
