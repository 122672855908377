import React from 'react';
import '../../App.css';
import Project from '../Projects/Project'
import Footer from '../Footer';

export default function Projectpages() {
  return (
    <>
      <Project />
      <Footer />
    </>
  )
}
