import React from 'react'
// import ReactPaginate from "react-paginate";
import './Project.css';
import '../style.css'

import ProjectItem from './ProjectItem';

function Project() {
  const freshProds = [
    {
      src: 'images/projects/pedheat2.png',
      title: 'Pedestrian level heat exposure',
      text: 'Modeling and mapping urban heat using efficient microclimate modeling based on urban 3D models, and map the mean radiant temperature at multiple scales down to pedestrian level. ',
      path: 'https://xiaojianggis.github.io/pedheat/',
    },
    {
      src: 'images/projects/heatmetrics.png',
      title: 'Urban Heat Metrics',
      text: 'Different metrics are telling different heat stories. This project compares the satellite-derived land surface temperature and the modeling calculated the mean radiant temperature.',
      path: 'https://www.sciencedirect.com/science/article/abs/pii/S2212095523002092',
    },
    {
      src: 'images/projects/heatscape.jpeg',
      title: 'Urban HeatExpo',
      text: 'Mapping high resolution (1m) urban heat exposures in US cities based on microclimate modeling.',
      path: 'https://xiaojianggis.github.io/heatexpo/',
    },
    {
      src: 'images/projects/milliontree.jpeg',
      title: 'NYC million trees',
      text: 'Using historical Google Street View images and deep learning investigating the temporal change and inequities of street tree canopies in New York City.',
      path: 'https://gis-jiang.medium.com/examining-the-spatial-distribution-and-temporal-change-of-street-trees-using-google-street-view-af26e9c0273a',
      date: '07-16-2023'
    },
    {
      src: 'images/projects/environmentjustice.png',
      title: 'Environmental Justice',
      text: 'Examine the environmental inequities in terms of urban green spaces using satellite imagery, Google Street View, and land use/cover map.',
      path: 'https://www.sciencedirect.com/science/article/abs/pii/S1618866715000990',
      date: '06-07-2023'
    },
    {
      src: 'images/projects/covid-mobility.jpeg',
      title: 'Covid-19 and Mobility',
      text: 'Investigate associations between changes in human mobility from Google Mobility Data and the rate of new daily COVID-19 diagnoses in the US across the urban-rural gradient.',
      path: 'https://xiaojianggis.github.io/covid-19-google-mobility/',
      date: '05-14-2023'
    },
    {
      src: 'images/projects/shadefinder.png',
      title: 'Shade Finder',
      text: 'Using Google Street View, machine learning, sunlight exposure model, and network routing to provide a pedestrian level stratege to get rid of too much sunlight exposure.',
      path: '',
      date: '03-24-2023',
    },
    {
      src: 'images/projects/skyview.png',
      title: 'Sky View Factor Mapping',
      text: 'Using Google Street View and geometrical transform to estimate the sky view factor (openness of street canyons) and understand streetscape in a upside-down view.',
      path: 'https://gis-jiang.medium.com/using-google-street-view-to-map-the-openness-of-street-canyon-6f904d5a7a2d',
      date: '02-27-2023'
    },
    {
      src: 'images/projects/mapshade.jpeg',
      title: 'Mapping Shade Distribution',
      text: 'Street trees provide shade in hot summers. Using Google Street View and building height model, we can quantify and map the amount of shade provided by street trees.',
      path: 'https://gis-jiang.medium.com/mapping-the-shade-provision-of-street-greenery-using-google-street-view-dbc3b9d67df7',
      date: '02-27-2023'
    },
    {
      src: 'images/projects/upsidedown.png',
      title: 'Ground-up Remote Sensing',
      text: "Remote sensing doen't have to be overhead view. Using hemispheircal images generated from panoramas to understand the urban environment from a bottom-up view.",
      path: 'https://www.sciencedirect.com/science/article/abs/pii/S0169204617301950',
      date: '03-24-2023',
    },
    {
      src: 'images/projects/streetdynamics.png',
      title: 'Streetscape dynamics',
      text: 'Analyzing the drivers of pedestrian activity in dense urban environment using massively collected human GPS trace and Google Street View data.',
      path: 'https://onlinelibrary.wiley.com/doi/abs/10.1111/tgis.12472',
      date: '02-27-2023'
    },
    {
      src: 'images/projects/sunglare.png',
      title: 'Sun Glare Mapping',
      text: 'Have you been annoyied by the dazzling sunlight driving? This project will help you out using Google Street View and machine learning.',
      path: 'https://medium.com/analytics-vidhya/mapping-sun-glare-dazzling-sun-while-driving-using-google-street-view-81c69f1e8238',
      date: '02-27-2023'
    },
    {
      src: 'images/projects/urbanremotesensing.jpeg',
      title: 'Urban Remote Sensing',
      text: 'High-resolution remotely sensed data provide a rapid, overhead view of urban space. It make urban environment monitoring and mapping efficient.',
      path: 'https://www.urbanspatial.info/research.html',
      date: '02-27-2023'
    },
    {
      src: 'images/projects/treepedia.png',
      title: 'Treepedia',
      text: 'Mapping the street greenery for cities around the world using geo-tagged Google Street View and computer vision.',
      path: 'https://senseable.mit.edu/treepedia',
      date: '02-27-2023'
    }
  ]
  // const [visible, setVisible] = useState(6);
  // const [currentPage, setCurrentPage] = useState(0);
  // const itemsPerPage = 100; // Number of items to display per page
  const itemsPerRow = 3; //number of iterms each row
  const numberItems = freshProds.length;
  const numRows = Math.ceil(numberItems / itemsPerRow);
  const numItemLeft = numberItems - numRows * itemsPerRow;

  console.log(numItemLeft);

  // Calculate the start and end index of items for the current page
  // const endIndex = currentPage + itemsPerPage;
  // console.log(currentPage);

  return (
    <>
      <div className='projects'>
        <h4 className='page__title'>Making cities a better place to live using <strong>science</strong>, <strong>data</strong>, and <strong>design</strong></h4>
        <div className='projects__container'>
          <div className='projects__wrapper'>
            {Array.from(Array(numRows), (e, i) => {
              return (
                <ul className='projects__items'>
                  {freshProds.slice(itemsPerRow * i, itemsPerRow * (i + 1)).map((freshprod) => (
                    <ProjectItem
                      src={freshprod.src}
                      title={freshprod.title}
                      subtitle={freshprod.text}
                      path={freshprod.path}
                    />
                  ))}
                </ul>
              )
            })}

            <ul className='projects__items'>
              {freshProds.slice((numRows) * itemsPerRow, numberItems).map((freshprod) => (
                <ProjectItem
                  src={freshprod.src}
                  title={freshprod.title}
                  subtitle={freshprod.text}
                  path={freshprod.path}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            pageCount={Math.ceil(freshProds.length / itemsPerPage)}
            onPageChange={handlePageChange}
            containerClassName="paginationBttns"
            activeClassName="paginationActive"
        /> */}
    </>
  );
}

export default Project
