import React from 'react'
import { Link } from 'react-router-dom'
import './Teaching.css'

function Teaching() {
  return (
    <>
    <div className='teaching'>
            <div className='teaching__container'>
                <div className='teaching__wrapper'>
                    <ul className='teaching__item'>
                        <div className='teaching__item__info'>
                            <h5 className='teaching__item__text'>
                                <a className='teachingLink' href = "https://github.com/xiaojianggis/big-spatial-data" target="_blank">Big Spatial Data Science </a>

                                {/* <Link className='blogs__item__link' to={`/Post/${props.id}`} target="_blank"> */}

                            </h5>
                            In Big data era, knowledge and skills for collecting and analyzing big spatial data become an essential skill for spatial data scientists. This course will teach students the concepts, techniques, and analytical methods for big spatial data. Methods for storing, processing, analyzing, and visualizing various types of big spatial data using cloud computing and advanced Python programming will be taught in this course. Examples of real big spatial data applications will be introduced in this course in order for students to get the practical skills in handling big spatial data. The course is designed for students who have programming experience or have finished GUS 5031 (GIS Programing) previously and want to reinforce the programming skills and learn advanced computing skills for solving big geospatial data problems. This course includes lectures and lab exercises. The knowledge and skills learned in this course further prepare students for an emerging career of Spatial Data Science.
                        </div>
                        
                        <div className='teaching__item__info'>
                            <h5 className='teaching__item__text'>
                                <a className='teachingLink' href='https://github.com/temple-geography/GUS-5073-Geovisualization' target="_blank">GUS-5073-Geovisualization</a>
                            </h5>
                            Maps can be powerful devices for communication, but also tools for exploration of relationships among social and physical processes manifesting in space. This course will address this dual purpose of maps as tools for visual communication and visual thinking. We will explore the world of cartography and geographic visualization (geovisualization) from many perspectives and through various methods. We will review theoretical concepts and traditional principles of cartography, such as map layout, typography, and color—subjects which are covered in greater depth in GUS 8065: Cartographic Design. We will learn how to use maps interactively for exploratory analysis and how to build and disseminate interactive maps for others to explore. Topics covered include fundamental concepts for geographic data representation, symbolization, map design, color theory, exploratory spatial data analysis, and tools and techniques in geovisualization. Technology is always changing, but the principles that lead to good map design remain the constant. This class will emphasize both design principles and practical skills. This course requires time, creativity, and a drive to learn. I will be handing you prescribed labs detailing how to visualize data. Instead of teaching you a single data visualization platform that may not be used, or even around in a few years, I want to give you a flavor of various geovisualization tools and help you to learn how to find, test, and teach new software to yourselves and each other. I believe this will give you valuable experience into how actual research takes place in academia and industry, where tools and standards are constantly changing, and it is not enough to learn one software. Sometimes you will be learning a tool or technology that I am not an expert in, and we will be learning together.
                        </div>

                        <div className='teaching__item__info'>
                            <h5 className='teaching__item__text'>
                                {/* <Link className='teachingLink' to={{ pathname: 'https://github.com/temple-geography/fundamentals-arcgis-pro' }} target="_blank">GUS-3062-Fundamentals of GIS</Link> */}
                                <a className='teachingLink' href = 'https://github.com/temple-geography/fundamentals-arcgis-pro' target="_blank">GUS-3062-Fundamentals of GIS</a>
                            </h5>
                            The purpose of this course is to teach the theory and practical use of Geographic Information Systems (GIS). Major components of the course include computer representation of geographic information, the construction of GIS databases, spatial analysis with GIS, application areas of GIS, and social and management issues that concern GIS. At the end of the course the student is expected to have an understanding of elementary GIS theory, working knowledge of ArcGIS, and the ability to develop GIS-based solutions to geographic modeling and analysis tasks. Note that this is not 'just' a software training course! You will indeed finish the course with advanced skills in a specific GIS software package. However, broader learning objectives for this course include general scientific literacy and graphicacy (the understanding and creation of maps and other graphic representations), as well as developing skills related to the design and evaluation of GIS analyses.
                        </div>
                    </ul>
                    
                </div>
            </div>
      </div>
    
    </>
  )
}

export default Teaching
