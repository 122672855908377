import React from 'react';
import './Footer.css';
// import { Button } from './Button';
// import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <p className='website-rights' style={{fontSize:20}}>Urban Spatial Informatics Lab, Univeristy of Pennsylvania © 2024</p>
        </div>
      </section>
    </div>
  );
}

export default Footer;
