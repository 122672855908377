import React from 'react';
import Navbar from './components/Navbar';
import './App.css';

import Home from './components/pages/Home';
import { HashRouter as Router, Switch, Route } from 'react-router-dom'; // HashRouter don't use BrowserRouter, you can not use router

import Projectpages from './components/pages/Projectpages';
import Publicationpages from './components/pages/Publicationpages';
import Teampage from './components/pages/Teampage';
import Teachingpages from './components/pages/Teachingpages';
import SignUp from './components/pages/SignUp';
import News from './components/News/News';
import PostPage from './components/PostPage/PostPage';
import Blogs from './components/Blogs/Blogs';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/projects' component={Projectpages} />
          <Route path='/publications' component={Publicationpages} />
          <Route path='/blogs' component={Blogs} />
          <Route path='/team' component={Teampage} />
          <Route path='/teaching' component={Teachingpages} />
          <Route path='/sign-up' component={SignUp} />

          <Route exact path="/news/:newsId" component={ News }/>
          <Route exact path="/blog/:pageNo" component={ Blogs } />
          <Route exact path="/Post/:postId" component={ PostPage } />
        </Switch>

        {/* blogs */}
        {/* <Route path="/article/gsv" component={Gsv} />
        <Route path="/article/sunglare" component={Sunglare} /> */}
      </Router>

    </>
  );
}

export default App;
