import React from 'react'
import Team from '../Team/Team'
import Footer from '../Footer'

function Teampage() {
  return (
    <>
      <Team/>
      <Footer/>
    </>
  )
}

export default Teampage