import React from 'react'
// import { Link } from 'react-router-dom';

function ProjectItem(props) {
  return (
    <>
      <li className='projects__item'>
          {/* <Link className='projects__item__link' to={props.path}> */}
          {/*this would add extermal link*/}
          {/* <Link className='projects__item__link' to={{ pathname: props.path }} target="_blank">
            <figure className='projects__item__pic-wrap' data-category={props.label}>
              <img
                className='projects__item__img'
                alt=''
                src={props.src}
              />
            </figure>
            <div className='projects__item__info'>
              <h2> {props.title} </h2>
              <h5 className='projects__item__text'>{props.subtitle}</h5>
              <p style={{ justifyContent: 'right' }}>{props.date}</p>
            </div>
          </Link> */}

          
          <a className='projects__item__link' href={props.path}>
            <figure className='projects__item__pic-wrap' data-category={props.label}>
              <img
                className='projects__item__img'
                alt=''
                src={props.src}
              />
            </figure>
            <div className='projects__item__info'>
              <h2> {props.title} </h2>
              <h5 className='projects__item__text'>{props.subtitle}</h5>
              <p style={{ justifyContent: 'right' }}>{props.date}</p>
            </div>
          </a>


      </li>
    </>
  )
}

export default ProjectItem