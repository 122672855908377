import React from 'react'
import { Link } from 'react-router-dom';

function BlogItem(props) {
  return (
    <>
      <li className='blogs__item'>
        {/* <Link className='blogs__item__link' to={{ pathname: props.path }} target="_blank"> */}
        {/* <Link className='blogs__item__link' to={`/blogarticle/${props.id}`} target="_blank"> */}
        <Link className='blogs__item__link' to={`/Post/${props.id}`} target="_blank">
          <div className='indexName'>
            {props.id + '.'}
          </div>
          <div className='blogs__item__info'>
            <h5 className='blogs__item__text'>{props.title}</h5>
          </div>
          <div className='blogs__item__info_date'>
            <h5 className='blogs__item__text'>{props.date}</h5>
          </div>
        </Link>

      </li>
    </>
  )
}

export default BlogItem