import React from 'react'
import { Link } from 'react-router-dom'
import './Team.css'

function Team() {
    return (
        <>
            <div className='team'>
                <div className='team__container'>
                    <div className='team__wrapper'>
                        <h4 className='team__title'>About us</h4>

                        <p className='aboutus__info'>
                        Urban spatial informatics lab (USIL) at <a href='https://www.design.upenn.edu/' className='intro__link'> Department of City and Regional Planning, School of Design, University of Pennsylvania</a> aims to combine <strong>Science</strong>, <strong>Data</strong>, 
                        and <strong> Design</strong> together to tackle the pressing urban challenges through the collaboration with communities. We apply and develop AI, Geospatial Data Science, 
                        Environmental Modeling, High-performance Computing, Socio-environmental analyses to better understand the dynamics of urban socio-enviornment system and make our cities
                        more livable, sustainable, equitable, and healthy. 
                        </p>
                        <br></br>
                        <h4 className='team__title'>Faculty</h4>
                        <h5 className='team__subtitle'>Xiaojiang Li, Assistant Professor of Urban Spatial Analytics and Geospatial Data Science, Email: jianglix@design.upenn.edu</h5>
                        
                        <ul className='team__item'>
                            <img
                                className='team__item__img'
                                alt=''
                                src={'images/team/li.jpeg'}
                            />

                            <div className='team__item__info'>
                                <div className='bio__info'>
                                    Xiaojiang is a tenure-track assistant professor at Department of City and Regional Planning, University of Pennsylvania. He is also the co-founder 
                                    of <a className='link__bio' href="https://www.biometeors.com/" target="_blank" >Biometeors</a>.
                                    He was an assistant professor at Department of Geography and Urban Studies, Temple University. 
                                    He was a Postdoctoral Fellow at MIT Senseable City Lab. He has been selected as the 50 Rising Stars in Geospatial World. He has been awarded the Global Young
                                    Scientist Award, World Geospatial Developers Conference. His research focuses on developing and applying geospatial analyses
                                    and data-driven approaches in the domain of urban studies. He has proposed to use Google Street View for urban environmental 
                                    studies and developed the Treepedia project, which aims to map street greenery for cities around the world. He is working
                                     on <a className='link__bio' href="https://xiaojianggis.github.io/heatexpo/" target='_blank'>HeatExpo</a> using artificial intelligence, remote sensing, urban microclimate modeling, and urban analytics to investigate
                                        the different vulnerabilities to climate change across different neighborhoods in the U.S. He is also working on using human 
                                        trace data to study human activities and investigate the connection between urban environments and human activities. 
                                        His research interests include Urban Science, Spatial Data Science, data-driven urban environment modeling, mobility
                                        and travel behavior profiling, urban computing and spatial analyses, and remote sensing. His research aims to provide a better understanding of human and urban environment systems and explore using digital technologies to benefit human society. His work has been featured in popular media outlets, including TIME, Wall Street Journal, Scientific American, The Guardian, Forbes, Atlantic-Citylab, Associated Press, and MIT News.
                                </div>                          
                            </div>
                        </ul>
                    </div>


                    <div className='team__wrapper'>
                        <h4 className='team__title'> Graduate student</h4>
                        <h5 className='team__subtitle'>Elizabeth Newnam, PhD student</h5>
                        <ul className='team__item'>
                            <img
                                className='team__item__img'
                                alt=''
                                src={'images/team/newman.png'}
                            />
                            <div className='team__item__info'>
                                Elizabeth is a Geography and Urban Studies PhD student at Temple University. Their research interests include quantitative geographic methodologies such as remote sensing, geographic information science, and python programming for geographic analysis, as well as sustainability, political ecology, and human-nature dynamics in the urban space.
                            </div>
                        </ul>

                    </div>

                    <div className='team__wrapper'>
                        <h5 className='team__subtitle'>Shengao Yi, PhD student</h5>
                        <ul className='team__item'>
                            <img
                                className='team__item__img'
                                alt=''
                                src={'images/team/yi.jpeg'}
                            />
                            <div className='team__item__info'>
                                Shengao is a PhD student of at Department of City and Regional Planning, University of Pennsylvania. He finished his master degree at the University of Pennsylvania. His research interests include Geospatial Artificial Intelligence and Urban Analysis.                           
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Team