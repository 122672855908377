import React from 'react';
import '../../App.css';
import Publications from '../Publications/Publications';

export default function Publicationpages() {
  return (
    <>
      <Publications /> 
    </>
  )
}
