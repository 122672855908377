import React from 'react'
import '../News/NewCardItem.css'


function NewsCardItem(props) {


    return (
        <div className='news_article_container'>
            <h1>{props.text}</h1>
            <p className="title text-secondary">
                Date : {new Date(props.date).toLocaleDateString()}
                <p>News Type : {props.label}</p>
            </p>
        
            <div className='news__artitle_text'>
                <span className='news__artitle_text' dangerouslySetInnerHTML={{ __html: props.description }} />
            </div>

            <figure className='' data-category={props.label}>
                <img
                    className='news__article__img'
                    alt='Travel Image'
                    src={props.img}
                />
            </figure>
        </div>
    )
}

export default NewsCardItem
