import React from 'react'
import { Link } from 'react-router-dom'

function BookItem(props) {
  return (
    <>
      <li className='books__item'>
        {/* <Link className='publications__item__link' to={{ pathname: props.path }} target="_blank"> */}
        <a className='publications__item__link' href={props.path} target="_blank">
          <img
            className='publications__item__img'
            alt=''
            src={props.src}
          />

          <div className='indexName'>
            {props.id}
          </div>

          <div className='publications__item__info'>
            <h5 className='publications__item__text'>{props.name}</h5>
          </div>
        </a>
        {/* </Link> */}
      </li>
    </>
  )
}

export default BookItem