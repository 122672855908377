import React, {useState, useEffect, useCallback} from 'react'
import './PostPage.css'
// import db from '../../utils/db.json'
// import blog from '../../utils/blog.json'

function PostCard(props) {
  const [authorName, setauthorName] = useState('')

  const fetchData = useCallback(() => {
    // const user = db.authors[props.id]
    console.log(props)
    setauthorName(props.edit)
  }, [props.authorId])
  
  useEffect(() => {
    fetchData()
  }, [fetchData])


// console.log(user);

  return (
    <div className="card w-100">
      <h1>{props.title}</h1>
      <p className="title text-secondary">
        Date : {new Date(props.date).toLocaleDateString()}
      </p>
      <p>Author : {authorName}</p>

      <div className=" p-5 ">
        <div className="mainContent mx-auto">
          {props.description}
        </div>
      </div>
    </div>
  )
}
export default PostCard
