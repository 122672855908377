import React, { useState, useEffect } from 'react'
import BlogItem from './BlogItem';
import BlogItemURLpath from './BlogItemURLpath';
// import CustBlogItem from './CustBlogItem';
// import BlogPagination from '../BlogPagination/BlogPagination'
import ReactPaginate from "react-paginate";
import '../style.css'
import './Blogs.css'

import blogjson from '../../utils/blog.json'
import Footer from '../Footer';


// const blogRecords = [
//   {
//     id: 11,
//     title: 'gsv',
//     date: '12-7-2022',
//     text: 'The world’s first mobile App to check sun glare',
//     // path: '/Articles/Gsv'
//     path: "none"
//     // path: 'https://gis-jiang.medium.com/the-worlds-first-mobile-app-to-check-sun-glare-3611d47a8c9c',
//   },
//   {
//     id: '10',
//     title: 'sunglare',
//     date: '11-2-2020',
//     text: "Choropleth mapping of half-million buildings in Philadelphia using Mapbox",
//     path: 'https://gis-jiang.medium.com/map-choropleth-map-of-half-million-building-footprints-using-mapbox-99b378a14226',
//   },
//   {
//     id: '9',
//     date: '09-17-2020',
//     text: "Using Google Street View to map the openness of street canyons of Boston",
//     path: 'https://gis-jiang.medium.com/using-google-street-view-to-map-the-openness-of-street-canyon-6f904d5a7a2d',
//   },
//   {
//     id: '8',
//     date: '09-10-2020',
//     text: "Overlay shapefile on raster data using Python",
//     path: '',
//   },
//   {
//     id: '7',
//     date: '09-10-2020',
//     text: "Create beautiful choropleth map using Python and Matplotlib",
//     path: '',
//   },
//   {
//     id: '6',
//     date: '08-10-2020',
//     text: "Map projection issues in pyproj",
//     path: 'https://gis-jiang.medium.com/change-projections-of-point-feature-using-different-versions-of-pyproj-9e4082356f68',
//   },
//   {
//     id: '5',
//     date: '08-10-2020',
//     text: "Create points along street using Fiona",
//     path: 'https://gis-jiang.medium.com/create-point-feature-class-shapefile-using-fiona-ae78e1a4b480',
//   },
//   {
//     id: '4',
//     date: '08-10-2020',
//     text: "Mosaic georeferenced raster data using Rasterio and Python",
//     path: '',
//   },
//   {
//     id: '3',
//     date: '09-14-2019',
//     text: "Configure the Anaconda and virtual environment for geospatial analysis",
//     path: '',
//   },
//   {
//     id: '2',
//     date: '09-14-2019',
//     text: "Mapping the sunglare using Google Street View and Deep Learning.",
//     path: 'https://medium.com/analytics-vidhya/mapping-sun-glare-dazzling-sun-while-driving-using-google-street-view-81c69f1e8238',
//   },
//   {
//     id: '1',
//     title: 'gsv',
//     date: '06-10-2018',
//     text: "Mapping the shade using Google Street View",
//     path: 'https://gis-jiang.medium.com/mapping-the-shade-provision-of-street-greenery-using-google-street-view-dbc3b9d67df7',
//   }
// ]

function Blogs({ match }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [jsonLength, setJsonLength] = useState(0);
  const [jsonArray, setJsonArray] = useState([]);

  // const itemsPerRow = 1; //number of iterms each row
  // const numberItems = blogRecords.length;
  const itemsPerPage = 10; // Number of items to display per page

  // Function to handle page change
  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * itemsPerPage
    setCurrentPage(offset);
  };

  useEffect(() => {
    const jsonArray = Object.values(blogjson.records);
    // Set the length of the JSON object
    setJsonLength(jsonArray.length);
    console.log(jsonArray.length)
    setJsonArray(jsonArray)
  }, []);


  return (
    <>
      <div className='blog__align'>
        <div className='blogs'>
          <div className='blogs__container'>
            <div className='blogs__wrapper'>
              <h4 className='blogTitle'>Blogs</h4>
              <ul className='blogs__items'>
                {
                  jsonArray.slice(currentPage, currentPage + itemsPerPage).map(item => (
                    item.path === "none" ?
                      <BlogItem
                        id={item.id}
                        title={item.title}
                        path={item.path}
                        date={item.date}
                      /> :
                      <BlogItemURLpath
                        id={item.id}
                        title={item.title}
                        path={item.path}
                        date={item.date}
                      />)
                  )}
              </ul>


              {/* {Array.from(Array(numberItems + 1), (e, i) => {
              return (
                <ul className='blogs__items'>
                  {blogRecords.slice(i, i + 1).map((freshprod) => (
                    freshprod.path == "none" ?
                      <BlogItem
                        id={freshprod.id}
                        title={freshprod.title}
                        name={freshprod.text}
                        path={freshprod.path}
                        date={freshprod.date}
                      /> :
                      <BlogItemURLpath
                        id={freshprod.id}
                        title={freshprod.title}
                        name={freshprod.text}
                        path={freshprod.path}
                        date={freshprod.date}
                      />

                  ))}
                </ul>
              )
            })} */}


            </div>
          </div>

          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            pageCount={Math.ceil(jsonLength / itemsPerPage)}
            onPageChange={handlePageChange}
            containerClassName="paginationBttns"
            activeClassName="paginationActive"
          />

        </div>
      </div>
      <Footer />
    </>
  )
}

export default Blogs