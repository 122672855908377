import React from 'react'
// import { Container, Row, Col } from 'react-grid-system';
import PublicationItem from './PublicationItem';
import BookItem from './BookItem'
import './Publications.css'


function Publications() {
    const publicationRecords = [
        {
            id: '49',
            src: 'images/publications/pub49.png',
            text: <><b>Xiaojiang Li</b> (2024). Mapping pedestrian network level outdoor heat hazard distributions in Philadelphia, Environment and Planning B: Urban Analytics and City Science, (Accepted)</>,
            path: 'images/publications/pub49.pdf',
        }, 
        {
            id: '48',
            src: 'images/publications/pub48.png',
            text: <><b>Xiaojiang Li</b>, Guoqing Wang, Benjamin Zaichik, Angel Hsu, TC Chakraborty, (2024). Sensitivity and vulnerability to summer heat extremes in major cities of the United States, Environmental Research Letter, (Accepted)</>,
            path: 'https://iopscience.iop.org/article/10.1088/1748-9326/ad6c64',
        }, 
        {
            id: '47',
            src: 'images/publications/pub47.png',
            text: <>Shengao Yi, <b>Xiaojiang Li</b>, Ruoyu Wang, Ziyi Guo, Xinyu Dong, Yixuan Liu,Qian Xu, (2024). Interpretable spatial machine learning insights into urban sanitation challenges: A case study of human feces distribution in San Francisco, Sustinable City and Society,(113), 105695</>,
            path: 'https://www.sciencedirect.com/science/article/pii/S2210670724005201?via%3Dihub',
        }, 
        {
            id: '46',
            src: 'images/publications/pub46.jpg',
            text: <>Rieves, E. S., Reid, C. E., Carlson, K., & <b>Xiaojiang Li</b> (2024). Do environmental attitudes and personal characteristics influence how people perceive their exposure to green spaces? Landscape and Urban Planning, 248, 105080.</>,
            path: 'https://www.sciencedirect.com/science/article/pii/S0169204624000793',
        }, 
        {
            id: '45',
            src: 'images/publications/pub45.png',
            text: <>Yan, J, Huang, X, Wang, S, He, Y, Hohl, A, <b>Xiaojiang Li</b>, Aly, M. (2023). Toward a comprehensive understanding of eye-level urban greenness: a systematic review. International Journal of Digital Earth, 16, 4769-4789.</>,
            path: 'images/publications/pub45.pdf',
        }, 
        {
            id: '44',
            src: 'images/publications/pub44.png',
            text: <><b>Xiaojiang Li</b>, TC, Wang, G. (2023). Comparing land surface temperature and mean radiant temperature for urban heat
            mapping in Philadelphia. Urban Climate, 101615.</>,
            path: 'images/publications/pub44.pdf',
        }, 
        {
            id: '43',
            src: 'images/publications/pub43.png',
            text: <>Kianmehr, A., Lim, T. C., & <b>Li, X</b>. (2023). Comparison of different spatial temperature data sources and resolutions for use in understanding intra-urban heat variation. Sustainable Cities and Society, 96, 104619.</>,
            path: 'images/publications/pub43.pdf',
        }, 
        {
            id: '42',
            src: 'images/publications/pub42.png',
            text: <>Qiu, W., Li, W., Liu, X., Zhang, Z., <b>Li, X.</b>, & Huang, X. (2023). Subjective and objective measures of streetscape perceptions: Relationships with property value in Shanghai. Cities, 132, 104037.</>,
            path: 'images/publications/pub42.pdf',
        }, 
        {
            id: '41',
            src: 'images/publications/pub41.png',
            text: <>Yoo, E. H., Roberts, J. E., Eum, Y., <b>Li, X.</b>, Chu, L., Wang, P., & Chen, K. (2022). Short-term exposure to air pollution and mental disorders: a case-crossover study in New York City. Environmental Research: Health, 1(1), 015001.</>,
            path: 'images/publications/pub41.pdf',
        }, 
        {
            id: '40',
            src: 'images/publications/pub40.png',
            text: <>Qiu, W., Zhang, Z., Liu, <b>Li, X,</b> W., Li, X., Xu, X., & Huang, X. (2022). Subjective or objective measures of street environment, which are more effective in explaining housing prices?. Landscape and Urban Planning, 221, 104358.</>,
            path: 'images/publications/pub40.pdf',
        }, 
        {
            id: '39',
            src: 'images/publications/pub39.jpeg',
            text: <>Xu, X., Qiu, W., Li, W., Liu, X., Zhang, Z., <b>Li, X.</b>, & Luo, D. (2022). Associations between street-view perceptions and housing prices: Subjective vs. objective measures using computer vision and machine learning techniques. Remote Sensing, 14(4), 891.</>,
            path: 'images/publications/pub39.pdf',
        }, 
        {
            id: '38',
            src: 'images/publications/pub38.png',
            text: <>Yoo, E, Roberts, J, Eum, Y, <b>Xiaojiang Li</b>, Knoty, K, 2021, Assessing the effects of urban green space on mental health: A neighborhood-scale analysis in New York City Environmental Research.</>,
            path: 'images/publications/pub39.pdf',
        }, 
        {
            id: '37',
            src: 'images/publications/pub37.png',
            text: <><b>Xiaojiang Li</b>, Guoqing Wang, 2021, Examining runner's outdoor heat exposure using urban microclimate modeling and GPS trajectory mining, Computers, Environment and Urban Systems.</>,
            path: 'images/publications/pub37.pdf',
        }, 
        {
            id: '36',
            src: 'images/publications/pub36.png',
            text: <><b>Xiaojiang Li</b>, Guoqing Wang, 2021, GPU parallel computing for mapping urban outdoor heat exposure, Theoretical and Applied Climatology</>,
            path: 'images/publications/pub36.pdf',
        }, 
        {
            id: '35',
            src: 'images/publications/pub35.png',
            text: <><b>Xiaojiang Li</b>, 2021, Investigating the spatial distribution of resident’s outdoor heat exposure across neighborhoods of Philadelphia, Pennsylvania using urban microclimate modeling, Sustainable Cities and Society.</>,
            path: 'images/publications/pub35.pdf',
        }, 
        {
            id: '34',
            src: 'images/publications/pub34.png',
            text: <>A, Sevtsuk, R, Basu, <b>Xiaojiang Li</b>, 2021, A big data approach to understanding pedestrian route choice preferences: Evidence from San Francisco, Travel Behaviour and Society.</>,
            path: 'images/publications/pub34.pdf',
        }, 
        {
            id: '33',
            src: 'images/publications/pub33.png',
            text: <>Mennis, J., <b>Li, X.</b>, Meenar, M., Coatsworth, J. D., McKeon, T. P., & Mason, M. J. (2021). Residential greenspace and urban adolescent substance use: exploring interactive effects with peer network health, sex, and executive function. International journal of environmental research and public health, 18(4), 1611.</>,
            path: 'images/publications/pub33.pdf',
        }, 
        {
            id: '32',
            src: 'images/publications/pub32.png',
            text: <>Wang, R., Feng, Z., Pearce, J., Yao, Y., <b>Li, X.</b>, & Liu, Y. (2021). The distribution of greenspace quantity and quality and their association with neighbourhood socioeconomic conditions in Guangzhou, China: A new approach using deep learning method and street view images. Sustainable Cities and Society, 66, 102664.</>,
            path: 'images/publications/pub37.pdf',
        }, 
        {
            id: '31',
            src: 'images/publications/pub31.png',
            text: <>Lin, J., Wang, Q., & <b>Li, X.</b> (2021). Socioeconomic and spatial inequalities of street tree abundance, species diversity, and size structure in New York City. Landscape and Urban Planning, 206, 103992.</>,
            path: 'images/publications/pub31.pdf',
        }, 
        {
            id: '30',
            src: 'images/publications/pub30.jpeg',
            text: <><b>Xiaojiang Li</b>, (2021). Examining the spatial distribution and temporal change of the green view index in New York City using Google Street View images and deep learning. Environment and Planning B: Urban Analytics and City Science, 48(7), 2039-2054.</>,
            path: 'images/publications/pub30.pdf',
        }, 
        {
            id: '29',
            src: 'images/publications/pub29.jpeg',
            text: <><b>Xiaojiang Li</b>, Rudolph, A, Mennis, J. (2020). Association between population mobility reductions and new COVID-19 diagnoses in the United States along the urban–rural gradient, February–April, 2020. Preventing chronic disease, 17.</>,
            path: 'images/publications/pub29.pdf',
        }, 
        {
            id: '28',
            src: 'images/publications/pub28.png',
            text: <>Kumakoshi, Y., Chan, S. Y., Koizumi, H., <b>Li, X.</b>, & Yoshimura, Y. (2020). Standardized green view index and quantification of different metrics of urban green vegetation. Sustainability, 12(18), 7434.</>,
            path: 'images/publications/pub28.pdf',
        }, 
        {
            id: '27',
            src: 'images/publications/pub27.png',
            text: <><b>Xiaojiang Li</b>, Cai, B. Y., Qiu, W., Zhao, J., & Ratti, C. (2019). A novel method for predicting and mapping the occurrence of sun glare using Google Street View. Transportation research part C: emerging technologies, 106, 132-144.</>,
            path: 'images/publications/pub27.pdf',
        }, 
        {
            id: '26',
            src: 'images/publications/pub26.png',
            text: <><b>Li, X.</b>, Duarte, F., & Ratti, C. (2021). Analyzing the obstruction effects of obstacles on light pollution caused by street lighting system in Cambridge, Massachusetts. Environment and Planning B: Urban Analytics and City Science, 48(2), 216-230.</>,
            path: 'images/publications/pub26.pdf',
        }, 
        {
            id: '25',
            src: 'images/publications/pub25.png',
            text: <><b>Xiaojiang Li</b>& Ratti, C. (2019). Using Google street view for street-level urban form analysis, a case study in Cambridge, Massachusetts. In The Mathematics of Urban Morphology (pp. 457-470). Birkhäuser, Cham.</>,
            path: 'images/publications/pub25.pdf',
        }, 
        {
            id: '24',
            src: 'images/publications/pub24.png',
            text: <><b>Xiaojiang Li</b> & Ghosh, D. (2018). Associations between body mass index and urban “green” streetscape in Cleveland, Ohio, USA. International journal of environmental research and public health, 15(10), 2186.</>,
            path: 'images/publications/pub24.pdf',
        }, 
        {
            id: '23',
            src: 'images/publications/pub23.png',
            text: <><b>Xiaojiang Li</b> & Ratti, C. (2019). Mapping the spatio-temporal distribution of solar radiation within street canyons of Boston using Google Street View panoramas and building height model. Landscape and Urban Planning, 191, 103387. </>,
            path: 'images/publications/pub23.pdf',
        }, 
        {
            id: '22',
            src: 'images/publications/pub22.png',
            text: <>Villeneuve, P. J., Ysseldyk, R. L., Root, A., Ambrose, S., DiMuzio, J., Kumar, N., <b>Li, X</b> & Shooshtari, M. (2018). Comparing the normalized difference vegetation index with the Google Street view measure of vegetation to assess associations between greenness, walkability, recreational physical activity, and health in Ottawa, Canada. International Journal of Environmental Research and Public Health, 15(8), 1719.</>,
            path: 'images/publications/pub22.pdf',
        }, 
        {
            id: '21',
            src: 'images/publications/pub21.png',
            text: <><b>Xiaojiang Li</b>, Santi, P., Courtney, T. K., Verma, S. K., & Ratti, C. (2018). Investigating the association between streetscapes and human walking activities using Google Street View and human trajectory data. Transactions in GIS, 22(4), 1029-1044.</>,
            path: 'images/publications/pub21.pdf',
        }, 
        {
            id: '20',
            src: 'images/publications/pub20.png',
            text: <>Zhang, W., Witharana, C., Li, W., Zhang, C., <b>Li, X.</b>, & Parent, J. (2018). Using deep learning to identify utility poles with crossarms and estimate their locations from google street view images. Sensors, 18(8), 2484.</>,
            path: 'images/publications/pub20.pdf',
        }, 
        {
            id: '19',
            src: 'images/publications/pub19.png',
            text: <><b>Xiaojiang Li</b>, Cai, B. Y., & Ratti, C. (2018). Using street-level images and deep learning for urban landscape studies. Landscape Architecture Frontiers, 6(2), 20-30.</>,
            path: 'images/publications/pub19.pdf',
        }, 
        {
            id: '18',
            src: 'images/publications/pub18.png',
            text: <>Fangying Gong, Zhaocheng Zeng, Fan Zhang, <b>Li, X,</b> Edward Ng, Les Norford. (2018). Mapping sky, tree, and building view factors of street canyons in a high-density urban environment, Building and Environment. 134, 155-167.</>,
            path: 'images/publications/pub18.pdf',
        }, 
        {
            id: '17',
            src: 'images/publications/pub17.png',
            text: <><b>Xiaojiang Li</b>, et al. (2018), Mapping the spatial distribution of shade provision of street trees in Boston using Google Street View panoramas, Urban Forestry and Urban Greening, 31, 109-119.</>,
            path: 'images/publications/pub17.pdf',
        }, 
        {
            id: '16',
            src: 'images/publications/pub16.png',
            text: <><b>Xiaojiang Li</b>, Carlo Ratti, Ian Seiferling. (2018). Quantifying the shade provision of street trees in urban landscape: A case study in Boston, USA, using Google Street View, Landscape and Urban Planning, 169, 81-91, (link). (One of most downloaded papers).</>,
            path: 'images/publications/pub16.pdf',
        }, 
        {
            id: '15',
            src: 'images/publications/pub15.png',
            text: <><b>Xiaojiang Li</b>, et al. (2017). Building block level urban land use information retrieval based on Google Street View images, GIScience and Remote Sensing, 2017, 1-17.</>,
            path: 'images/publications/pub15.pdf',
        }, 
        {
            id: '14',
            src: 'images/publications/pub14.png',
            text: <><b>Xiaojiang Li</b>, Carlo Ratti, Ian Seiferling. (2017), Mapping Urban Landscapes Along Streets Using Gooogle Street View, Advances in Cartography and GIScience, 2017.</>,
            path: 'images/publications/pub14.pdf',
        }, 
        {
            id: '13',
            src: 'images/publications/pub13.png',
            text: <>Zhang W., W. Li, C. Zhang. D. Hanink, <b>Li, X,</b> and W. Wang. (2017). Parcel-based urban land use classification in megacity using airborne LiDAR, high resolution orthoimagery, and Google Street View. Computers, Environment and Urban Systems, 64, (215-268).</>,
            path: 'images/publications/pub13.pdf',
        }, 
        {
            id: '12',
            src: 'images/publications/pub12.png',
            text: <>Zhang, W., Li, W., Zhang, C., Hanink, D. M., <b>Li, X,</b> & Wang, W. (2017). Parcel feature data derived from Google Street View images for urban land use classification in Brooklyn, New York City for urban land use classification in Brooklyn, New York City. Data in brief, 12, 175-179.</>,
            path: 'images/publications/pub12.pdf',
        }, 
        {
            id: '11',
            src: 'images/publications/pub11.png',
            text: <>Zhang W., W. Li, C. Zhang, and <b>Li, X</b> (2017). Incorporating Spectral Similarity into Markov Chain Geostatistical Cosimulation for Reducing Smoothing Effect in Land Cover Post-Classification". IEEE Journal of Selected Topics in Applied Earth Observations and Remote Sensing, 2016, doi: doi: 10.1109/JSTARS.2016.2596040.</>,
            path: 'images/publications/pub11.pdf',
        }, 
        {
            id: '10',
            src: 'images/publications/pub10.png',
            text: <><b>Xiaojiang Li</b>, Chuanrong Zhang, Environmental inequities in terms of different types of urban greenery in Hartford, Connecticut, USA. Urban Forestry and Urban Greening, 18(1).</>,
            path: 'images/publications/pub10.pdf',
        }, 
        {
            id: '9',
            src: 'images/publications/pub9.png',
            text: <><b>Xiaojiang Li</b>, Chuanrong Zhang, Weidong Li, (2016). Modelling building proximity to greenery in a three-dimensional perspective using multi-source remotely sensed data, Journal of Spatial Science, (2016): 1-15.</>,
            path: 'images/publications/pub9.pdf',
        }, 
        {
            id: '8',
            src: 'images/publications/pub8.png',
            text: <><b>Xiaojiang Li</b>, Zhang, C, Li, W, Kuzovkina, Y, Weiner, D. (2015). Who lives in greener neighborhoods? The distribution of street greenery and its association with residents’ social conditions in Hartford, Connecticut, USA, Urban Forestry and Urban Greening, 2015, 14(4).</>,
            path: 'images/publications/pub8.pdf',
        }, 
        {
            id: '7',
            src: 'images/publications/pub7.png',
            text: <><b>Xiaojiang Li</b>, Zhang, C., & Li, W. (2015). Does the Visibility of Greenery Increase Perceived Safety in Urban Areas? Evidence from the Place Pulse 1.0 Dataset. ISPRS International Journal of Geo-Information, 4(3), 1166-1183.</>,
            path: 'images/publications/pub7.pdf',
        }, 
        {
            id: '6',
            src: 'images/publications/pub6.png',
            text: <><b>Xiaojiang Li</b>, Zhang, C., Li, W., Ricard, R., Meng, Q., Zhang, W. (2015). Assessing street-level urban greenery using Google Street View and a modified green view index, Urban Forestry and Urban Greening, 2015, 14(3). (One of most cited and downloaded papers).</>,
            path: 'images/publications/pub6.pdf',
        }, 
        {
            id: '5',
            src: 'images/publications/pub5.png',
            text: <><b>Xiaojiang Li</b>, Q. Meng, W. Li, C. Zhang, T. Jansco, and S. Mavromatis. (2014). "An explorative study on the proximity of buildings to green spaces in urban areas using remotely sensed imagery." Annals of GIS 20, 3 (2014): 193-203.</>,
            path: 'images/publications/pub5.pdf',
        }, 
        {
            id: '4',
            src: 'images/publications/pub4.png',
            text: <><b>Xiaojiang Li</b>, Qingyan Meng, Xingfa Gu, Tamas Jasco. (2013). A hybrid method combining pixel based and object based methods and its applications in Hungary using Chinese HJ-1 Satellite image, International Journal of Remote Sensing, 2013, 34(13), 4655-4667.</>,
            path: 'images/publications/pub4.pdf',
        }, 
        {
            id: '3',
            src: 'images/publications/pub3.png',
            text: <><b>Xiaojiang Li</b>, Qingyan Meng, Chunmei Wang, Miao Liu. (2013). A hybrid model of object- and pixel based classification of Remotely sensed data, Geo-spatial Information Science, 2013, 15(5) (In Chinese). </>,
            path: 'images/publications/pub3.pdf',
        }, 
        {
            id: '2',
            src: 'images/publications/pub2.png',
            text: <>Ke Wang, Xingfa Gu, Tao Yu, Jintang Lin, Guiping Wu and <b>Li, X</b> Segmentation of high resolution remotely sensed imagery combining spectral similarity with phase congruency, J. of Infrared Millim. Waves, 2013, 32(1):73-79 (In Chinese).</>,
            path: 'images/publications/pub2.pdf',
        }, 
        {
            id: '1',
            src: 'images/publications/pub1.png',
            text: <>Chunzhu Wei, Qingyan Meng, Wenfeng Zheng, <b>Li, X,</b> Xi Wei, Liang Wang, The study of Quantitative relationship between Land surface temperature and land cover of Guangzhou, Remote Sensing Technology and Application, 2013, 28(6): 955-963 (In Chinese).</>,
            path: 'images/publications/pub1.pdf',
        }

    ]

    const booksRecords = [
        {
            id: '1',
            src: 'images/books/book1.png',
            text: <>Xiaojiang Li (2021), Urban Remote Sensing Using Ground‐Based Street View Images, Urban Remote Sensing: Monitoring, Synthesis and Modeling in the Urban Environment 2nd Edition, Wiley Blackwell.</>,
            path: 'images/publications/book-urban-remote-sensing.pdf' //'https://onlinelibrary.wiley.com/doi/abs/10.1002/9781119625865.ch5',
        },         
        {
            id: '2',
            src: 'images/books/book2.png',
            text: <>Xiaojiang Li (2021),  pedestrian-level strategy to minimize outdoor sunlight exposure. Artificial Intelligence, Machine Learning, and Optimization Tools for Smart Cities: Designing for Sustainability, 123-134.</>,
            path: 'https://link.springer.com/chapter/10.1007/978-3-030-84459-2_7'
        }, 
    ]
    
    const itemsPerRow = 1; //number of iterms each row
    const numberItems = publicationRecords.length;
    const bookNums = booksRecords.length;

    return (
        <>
            <div className='publications'>
                <div className='publications__container'>
                    <div className='publications__wrapper'>
                        <h4 className='publicationTitle'>Books (chapter)</h4>
                        {Array.from(Array(bookNums), (e, i) => {
                            return (
                            <ul className='projects__items'>
                                {booksRecords.slice(itemsPerRow*i, itemsPerRow*(i+1)).map((bookRecord) => (
                                    <BookItem
                                        src={bookRecord.src}
                                        id={bookRecord.id}
                                        name={bookRecord.text}
                                        path={bookRecord.path}
                                    />
                                ))}
                            </ul>
                            )
                        })}
                    </div>
                </div>
            </div>
            
            <div className='publications'>
                <div className='publications__container'>
                    <div className='publications__wrapper'>
                        <h4 className='publicationTitle'>Journal Publications</h4>
                        {Array.from(Array(numberItems), (e, i) => {
                            return (
                            <ul className='projects__items'>
                                {publicationRecords.slice(itemsPerRow*i, itemsPerRow*(i+1)).map((freshprod) => (
                                    <PublicationItem
                                        src={freshprod.src}
                                        id={freshprod.id}
                                        name={freshprod.text}
                                        path={freshprod.path}
                                    />
                                ))}
                            </ul>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Publications