import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
        {/* <Link className='cards__item__link' to={{ pathname: props.path }} target="_blank">
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.icon}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            <p style={{ justifyContent: 'right', fontSize:'14px' }}>{props.date}</p>
          </div>
        </Link> */}

        <a href={props.path} target="_blank">
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.icon}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            <p style={{ justifyContent: 'right', fontSize: '14px' }}>{props.date}</p>
          </div>
        </a>


      </li>
    </>
  );
}

export default CardItem;
